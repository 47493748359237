import React, { Component } from "react"
import Layout from "../components/layout"
import {navigate} from 'gatsby'

import loginSession from '../../lib/class/loginSession'


export default class LogoutPage extends Component {

	componentDidMount() {
		var authentication = new loginSession()
		authentication.signOut()
		.then((response) => {
			navigate("/");
		}).catch((err)=> {
			navigate("/");
		})
	}


	render() {
		return (
			<Layout activePath='logout' pageTitle="Logout" privatePage={true}>
				<p class="bannertext font-size-large">
					Signing out...
				</p>
			</Layout>
		)
  	}
}
